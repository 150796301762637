<template>
  <ModalLayout
    v-model="isOpen"
    content-class="ExportTasksDialog"
    big-header
    no-footer
    :max-width="800"
    padding="24px 28px 100px"
    @click:close="isOpen = false"
  >
    <div class="ExportTasksDialog__wrapper flex-grow-1">
      <div class="ExportTasksDialog__summary text-truncate">
        <div class="ExportTasksDialog__meta">
          <span class="ExportTasksDialog__meta-label">{{ $t('integration.Integration') }}</span>
          <span
            class="d-inline-flex align-center mr-10"
            v-text="integration && integration.name"
          />

          <span class="ExportTasksDialog__meta-label">{{ $t('integration.Project') }}</span>
          <span
            class="d-inline-flex align-center mr-10"
            v-text="(jiraProject && `[${jiraProject.key}] ${jiraProject.name}`) || $t('integration.UnknownProject')"
          />

          <span class="ExportTasksDialog__meta-label">{{ $t('integration.IssueType') }}</span>
          <span class="d-inline-flex align-center">
            <img
              v-if="taskMeta.taskTypeIconUrl"
              :alt="taskMeta.taskTypeName"
              :src="taskMeta.taskTypeIconUrl"
              class="mr-2"
            >
            <v-icon
              v-else
              :size="16"
              class="mr-2"
              style="margin-top: -2px"
              v-text="'mdi-help-circle-outline'"
            />
            <span v-text="taskMeta.taskTypeName" />
          </span>
        </div>
        <h1
          class="ExportTasksDialog__title"
          v-text="$t('integration.AddJiraTask')"
        />
      </div>

      <div class="ExportTasksDialog__buttons pt-4">
        <v-card
          class="px-4 d-flex align-center"
          elevation="0"
          :ripple="{ class: 'app-ripple' }"
          height="96"
          outlined
          color="#FAFAFA"
          style="border-color: #E3E8F6"
          :disabled="!integration"
          @click="openCreateDialog"
        >
          <img
            style="width: 128px; height: 85px"
            alt="Export new task icon"
            class="mr-4"
            src="@/assets/images/jira-issue-export-create.svg"
          >
          <div class="ExportTasksDialog__action-description">
            <div
              class="ExportTasksDialog__action-title"
              v-text="$t('integration.CreateNew')"
            />
            <div
              class="ExportTasksDialog__action-subtitle"
              v-text="$t('integration.CreateTaskFromThisIssueM')"
            />
          </div>
        </v-card>
        <v-card
          class="mt-6 px-4 d-flex align-center"
          elevation="0"
          :ripple="{ class: 'app-ripple' }"
          height="96"
          outlined
          color="#FAFAFA"
          style="border-color: #E3E8F6"
          :disabled="!integration"
          @click="openLinkDialog"
        >
          <img
            style="width: 128px; height: 85px"
            alt="Export new task icon"
            class="mr-4"
            src="@/assets/images/jira-issue-export-link.svg"
          >
          <div class="ExportTasksDialog__action-description">
            <div
              class="ExportTasksDialog__action-title"
              v-text="$t('integration.LinkExisting')"
            />
            <div
              class="ExportTasksDialog__action-subtitle"
              v-text="$t('integration.FindExistingJiraTaskAndConnectM')"
            />
          </div>
        </v-card>
      </div>
    </div>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'

import Dialog from '../store/orm/dialog'

import { JIRA } from '../constants'

export default {
  name: 'ExportTasksDialog',

  components: {
    ModalLayout,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    integrationId: { type: String, required: true },
    projectId: { type: String, required: true },
    issueIds: { type: Array, required: true },
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    integration() {
      return this.$store.getters['integration/get'](this.integrationId)
    },

    jiraProject() {
      const { integration } = this
      if (!integration) return null
      if (integration.integrationCode !== JIRA) return null
      if (!integration.jiraProjectKey || !integration.meta) return null
      return integration.meta.projects
        .find(({ key }) => key === integration.jiraProjectKey) || null
    },

    jiraIssueType() {
      const { integration, jiraProject: project } = this
      if (!project || !integration.jiraIssueType) return null

      return project.issuetypes
        .find(({ name, id }) => {
          if (integration.jiraIssueType?.id && id === integration.jiraIssueType.id) return true
          // fixme: explain/fix this, no idea what's happening here, but looks bad
          if (!integration.jiraIssueType?.id && name === (integration.jiraIssueType?.name || integration.jiraIssueType)) return true
        }) || null
    },

    taskMeta() {
      const { jiraIssueType } = this

      if (jiraIssueType) {
        return {
          taskTypeIconUrl: jiraIssueType.iconUrl,
          taskTypeName: jiraIssueType.name,
          assigneeAvatarUrl: require('../assets/images/jira-unassigned.png'),
          assigneeName: 'Automatic',
        }
      }

      return {
        taskTypeIconUrl: null,
        taskTypeName: 'Task',
        assigneeAvatarUrl: null,
        assigneeName: null,
      }
    },
  },

  watch: {
    integrationId: {
      immediate: true,
      async handler(integrationId) {
        if (integrationId) await this.fetchIntegrationMeta()
      },
    },
  },

  methods: {
    async ensureIntegration() {
      await this.$store.dispatch('integration/getForProject', {
        projectId: this.projectId,
        reload: false,
      })
      if (!this.integration) throw new Error('Programming error')
      return this.integration
    },

    async fetchIntegrationMeta() {
      const { integrationCode } = await this.ensureIntegration()
      if (integrationCode === JIRA) return this.fetchJiraMeta()
      throw new Error('Unhandled integration type: ' + integrationCode)
    },

    fetchJiraMeta() {
      const { $store, integrationId } = this
      return $store.dispatch('integration/getJiraMeta', { integrationId, reload: false })
    },

    openCreateDialog() {
      const { projectId, integration, issueIds } = this
      Dialog.open({
        componentName: 'ExportTasksCreateDialog',
        props: {
          projectId,
          integration,
          issueIds,
        },
      })
      this.isOpen = false
    },

    openLinkDialog() {
      const { projectId, integrationId, issueIds } = this
      Dialog.open({
        componentName: 'ExportTasksLinkDialog',
        props: {
          projectId,
          integrationId,
          issueIds,
        },
      })
      this.isOpen = false
    },
  },
}
</script>

<style lang="sass" scoped>
.ExportTasksDialog
  &__summary
    height: 84px

  &__meta
    font-size: 16px
    line-height: 24px
    display: flex

  &__meta-label
    color: #A09EB9
    margin-right: 10px

  &__title
    font-size: 32px
    font-weight: 500
    line-height: 44px
    letter-spacing: 0.005em
    margin-top: 16px

  &__action-title
    font-size: 20px
    font-weight: 500
    line-height: 32px
    letter-spacing: 0.005em

  &__action-subtitle
    color: #A09EB9
    font-size: 14px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0.005em

</style>
